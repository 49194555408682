import Base from "@/data/api/Base";

export default {
  getFolder(id, apiKey, url) {
    return Base(true).http.get(
      "/element/root-folders/" + id + `?auth=${apiKey}&url=${url}`,
    );
  },
  getFolders(apiKey, url) {
    return Base(true).http.get(
      `/element/root-folders?auth=${apiKey}&url=${url}`,
    );
  },
  getDriverInstances(apiKey, url) {
    return Base(true).http.get(
      `/element/driver-instances?auth=${apiKey}&url=${url}`,
    );
  },
};
