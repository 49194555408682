<template>
  <BaseContentLoader :title="'Subscriptions'" :isLoading="isLoading">
    <template slot="content">
      <BaseTable
        :headers="tableHeaders"
        :items="tableItems"
        itemName="smartServiceId"
        @canUnsubscribe="(item) => cancelSubscription(item)"
      >
        <template slot="unsubscribe-dialog-content">
          {{ "Do you really want to cancel this subscription?" }}
        </template>
      </BaseTable>

      <v-row class="pt-8">
        <v-btn
          class="mx-3"
          color="primary"
          @click="exportData()"
          :disabled="disabledExportData"
          data-cy="btn-export-data"
          ><v-icon left>mdi-export</v-icon>
          {{ $t("_ExportData") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import {
  delay,
  exportCSVData,
  sortByKey,
} from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  data() {
    return {
      isLoading: true,
      packages: [],
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadPackages");
      await this.$store.dispatch("loadSubscriptions");
      await this.$store.dispatch("loadServiceStoreData");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "hasValidBillingToken",
      "getCurrentOrganization",
      "getAllSubscriptions",
      "isCurrentOrganizationsAdmin",
      "isSystemAdmin",
      "getLocale",
      "getServiceStore",
    ]),
    getPricingCards() {
      let pricingCards = [];
      for (let i = 0; i < this.getServiceStore.length; i++) {
        for (let j = 0; j < this.getServiceStore[i].planVariants.length; j++) {
          pricingCards.push(this.getServiceStore[i].planVariants[j].fields);
        }
      }
      return pricingCards;
    },
    tableItems() {
      if (this.getPricingCards.length === 0) {
        return [];
      }
      return this.getAllSubscriptions
        .map((a) => {
          var obj = Object.assign({}, a);
          obj.startDate = moment(a.startDate)
            .locale(this.getLocale)
            .format("LL");
          obj.unsubscribable = true;
          obj.name = this.getPricingCards.filter(
            (el) => el.sapNumber === obj.bookedPackageId,
          )[0].planVariantName;
          if (moment(a.endDate).isValid()) {
            obj.endDate = moment(a.endDate).locale(this.getLocale).format("LL");
            obj.props = { disabled: true };
          } else {
            obj.endDate = "---";
          }
          return obj;
        })
        .sort((a, b) => sortByKey(b, a, "bookedPackageId"));
    },
    tableHeaders() {
      return [
        {
          text: this.$t("_Name").toUpperCase(),
          value: "name",
          columnFilter: true,
        },
        { text: this.$t("_Quantity").toUpperCase(), value: "quantity" },
        {
          text: "Package ID".toUpperCase(),
          value: "bookedPackageId",
          columnFilter: true,
        },
        {
          text: "Internal reference".toUpperCase(),
          value: "internalOrderId",
          columnFilter: true,
        },
        { text: this.$t("_StartDate").toUpperCase(), value: "startDate" },
        { text: this.$t("_EndDate").toUpperCase(), value: "endDate" },
        {
          text: this.$t("_Actions").toUpperCase(),
          value: "actions",
          align: "end",
        },
      ];
    },
    disabledExportData() {
      return false;
    },
  },
  methods: {
    async cancelSubscription(subscription) {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("cancelSubscription", subscription);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    exportData() {
      var now = moment().locale(this.getLocale).format("lll");

      var filename = [
        this.$t("_BookedLicences"),
        this.getCurrentOrganization.name,
        now,
      ].join("-");

      var headers = [
        { value: "name", text: this.$t("_Name") },
        { value: "bookedPackageId", text: "Package ID" },
        { value: "internalOrderId", text: "Internal reference" },
        { value: "startDate", text: this.$t("_StartDate") },
        { value: "endDate", text: this.$t("_EndDate") },
        { value: "quantity", text: this.$t("_Quantity") },
      ];
      exportCSVData(filename, [...this.tableItems], true, headers);
    },
  },
};
</script>
