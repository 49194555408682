<!-- MPDT210068-6116 - In this component the user has to enter all required
input parameters to establish a connection between our platform and elementIoT
-->
<template>
  <div>
    <v-text-field
      v-model="value.url"
      :label="$t('_Url') + ' *'"
      :rules="urlRules"
      data-cy="tf-url"
    />
    <v-textarea
      class="py-2"
      v-model="value.apiToken"
      outlined
      label="API-Key *"
      :rules="apiKeyRules"
      @focusout="loadFromElementIot"
    />
    <v-combobox
      v-model="value.folder"
      :no-data-text="$t('_APIKeyAndRootFirst')"
      :label="'Folder ID *'"
      :items="folders"
      item-text="id"
      item-value="id"
      data-cy="tf-folder"
      :return-object="false"
      :rules="apiKeyRules"
    >
      <template v-slot:item="data">
        <div>
          <span> {{ data.item.name }}</span
          ><br />
          <small class="text--secondary">{{ data.item.id }}</small>
        </div>
      </template>
    </v-combobox>
    <v-combobox
      v-model="value.driverInstance"
      :no-data-text="$t('_APIKeyFirst')"
      :label="'Driver-Instance ID *'"
      :items="driverInstances"
      item-text="id"
      item-value="id"
      data-cy="tf-driver-instance"
      :rules="apiKeyRules"
    >
      <template v-slot:item="data">
        <div>
          <span> {{ data.item.name }}</span
          ><br />
          <small class="text--secondary">{{ data.item.id }}</small>
        </div>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import {
  urlRules,
  applicationIdRules,
  apiKeyRules,
  organizationIdRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";
import ElementIot from "@/data/external/ElementIot";

export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          apiToken: "",
          url: "",
          applicationId: null,
          organizationId: "",
          folder: "",
          driverInstance: "",
        };
      },
    },
  },
  data() {
    return {
      driverInstances: [],
      folders: [],
    };
  },
  computed: {
    urlRules() {
      return urlRules();
    },
    applicationIdRules() {
      return applicationIdRules();
    },
    apiKeyRules() {
      return apiKeyRules();
    },
    organizationIdRules() {
      return organizationIdRules();
    },
  },
  methods: {
    async loadFromElementIot() {
      if (this.value.apiToken !== "" || this.value.apiToken !== null) {
        this.folders = (
          await ElementIot.getFolders(this.value.apiToken, this.value.url)
        ).data;
        this.driverInstances = (
          await ElementIot.getDriverInstances(
            this.value.apiToken,
            this.value.url,
          )
        ).data;
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.$emit("input", this.value);
      },
      deep: true,
    },
  },
};
</script>
